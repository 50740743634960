// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@app/fonts/SpaceMono/SpaceMonoNerdFont-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@app/fonts/SpaceMono/SpaceMonoNerdFont-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@app/fonts/SpaceMono/SpaceMonoNerdFont-Italic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("@app/fonts/SpaceMono/SpaceMonoNerdFont-BoldItalic.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face{font-family:"SpaceMono";src:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");font-weight:400;font-style:normal}@font-face{font-family:"SpaceMono";src:url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");font-weight:700;font-style:normal}@font-face{font-family:"SpaceMono";src:url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");font-weight:400;font-style:italic}@font-face{font-family:"SpaceMono";src:url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");font-weight:700;font-style:italic}body{font-family:"SpaceMono";font-size:12px;line-height:14px;letter-spacing:-0.6px}*{scrollbar-width:thin}body{background-color:#111;color:#fff;height:100vh;overflow:hidden}#main-container{height:93%;display:grid;grid-template-columns:25% 74.4%;column-gap:.5rem}#main-container img{width:100%}.container-block{border:1px solid hsla(0,0%,100%,.1);padding:.36rem}.main-scroll-block{overflow-y:auto;height:95vh}#left-section,#main-section{display:grid;gap:.5rem}`, "",{"version":3,"sources":["webpack://./src/app/scss/abstracts/_mixins.scss","webpack://./src/app/scss/base/_typography.scss","webpack://./src/app/scss/base/_global.scss","webpack://./src/app/scss/abstracts/_variables.scss"],"names":[],"mappings":"AAkBI,WACI,uBAAA,CACA,8DAAA,CACA,eAAA,CACA,iBAAA,CAJJ,WACI,uBAAA,CACA,8DAAA,CACA,eAAA,CACA,iBAAA,CAJJ,WACI,uBAAA,CACA,8DAAA,CACA,eAAA,CACA,iBAAA,CAJJ,WACI,uBAAA,CACA,8DAAA,CACA,eAAA,CACA,iBAAA,CCER,KACI,uBAAA,CACA,cAAA,CACA,gBAAA,CACA,qBAAA,CCVJ,EACI,oBAAA,CAUJ,KACI,qBCVY,CDWZ,UCVe,CDWf,YAAA,CACA,eAAA,CAUJ,gBACI,UAAA,CACA,YAAA,CACA,+BAAA,CACA,gBAAA,CAEA,oBACI,UAAA,CAIR,iBACI,mCAAA,CACA,cAAA,CAGJ,mBACI,eAAA,CACA,WAAA,CAUJ,4BACI,YAAA,CACA,SAAA","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
